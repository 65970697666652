<template>
    <v-sheet>
        <section id="hero-section">
            <hero-section></hero-section>
        </section>
        <section id="speaker-section">
            <speaker-section></speaker-section>
        </section>
        <section id="registration-card">
            <registration-card></registration-card>
        </section>
        <section id="footer">
            <app-footer></app-footer>
        </section>
    </v-sheet>
</template>

<script setup>
  import HeroSection from '@/components/events/HeroSection.vue';
  import SpeakerSection from '@/components/events/SpeakerSection.vue';
  import RegistrationCard from '@/components/events/RegistrationCard.vue';
  import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "EventRegistration",
    data: () => ({
       
    }),
    computed: {
        
    }
};
</script>

<style scoped>

#hero-section {
    background-size: 100vw 60vh;
    background-color: black;
    color: white;
}

#speaker-section {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}



#call-to-action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
}

#footer {
    height: 10vh;
}
</style>