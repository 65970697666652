<template>
    <v-card variant="flat" class="mx-auto d-flex align-center justify-center" height="100%" min-height="600"
        min-width="300" :image="require('@/assets/images/service-provider-hero.png')" theme="dark" color="transparent">
        <v-card-text class="text-center">
            <v-container>
            <v-row justify="center">
                <v-col cols="12" xl="4" lg="6" md="8" sm="9">
                    <p class="text-h6 text-white">{{ $t('FOR_SERVICE_PROVIDERS') }}</p>
            <p v-html="$t('SERVICE_PROVIDER_HERO_STATEMENT')"></p><br>
            <p>
                <v-btn size="large" variant="flat" rounded="xl"
                    color="#2979FF" class="pl-10 pr-10" @click="showTryNow()">
                    {{ $t('TRY_NOW') }}
                </v-btn>
            </p>
                </v-col>
            </v-row>
        </v-container>
            
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "HeroSection",
    computed: {
        path() {
            return this.$route.fullPath;
        }
    },
    methods: {
        showTryNow() {
            document.getElementById("try-now").scrollIntoView();
        }
    }
};
</script>

<style scoped>


</style>