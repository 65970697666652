<template>
    <v-container id="registration-form" fill-height class="bg-transparent-variant">
        <v-row justify="center">
            <v-col cols="12">
                <p class="pl-5 pt-5 pr-5 text-center text-h4">Don’t miss this opportunity to gain valuable insights and enhance your technology planning!</p>
            </v-col>
            <v-col cols="auto">
                <v-card variant="flat" class="mx-auto d-flex justify-center" height="600"
                min-width="300" max-width=400 color="transparent">
                <v-list lines="three">
                    <v-list-item title="Date" subtitle="November 14, 2024">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-calendar-blank"></v-icon>
                        </template>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item title="Location" subtitle="509 W. North Street">
                        <v-list-item-subtitle>Raleigh, NC 27603</v-list-item-subtitle>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-map-marker"></v-icon>
                        </template>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item title="Time" subtitle="5:30pm - 8:15pm">
                        <template v-slot:prepend>
                            <v-icon icon="mdi-clock"></v-icon>
                        </template>
                    </v-list-item>
                </v-list>
                </v-card>
            </v-col>
            <v-col cols="auto">
                <v-card variant="tonal" height="1200"
                min-width="300" max-width=500>
                    <v-divider></v-divider>
                    <v-card-text class="text-body-1 mt-10">
                <iframe src="https://survey.zohopublic.com/zs/NXClBt" frameborder='0' style='height:1200px;width:400px; background: #FFFFFF;' allowtransparency="true"
            marginwidth='0' marginheight='0' scrolling='no' allow='geolocation'></iframe>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "RegistrationCard",
    computed: {
        
    },
    data() {
        return {
            
        };
    },
    methods: {
        
    },
};
</script>