<template>
    <v-container>
        <v-row justify="center" class="mb-10">
            <v-col cols="12">
                <p class="text-h4 text-center">{{ title }}</p>
            </v-col>
            <v-col cols="6">
                <p class="text-h6 text-center">
                    {{ description }}
                </p>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-10">
            <v-col cols="auto" v-for="(item, i) in speakers" :key="i">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card variant="flat" class="mx-auto d-flex align-center justify-center" height="450"
                        min-height="450" min-width="300" :image="require('@/assets/events/' + item.picture)"
                        theme="dark" color="transparent" v-bind="props">
                        <v-overlay :model-value="!!isHovering" class="align-center justify-center" scrim="black"
                            opacity="1" contained>
                            <p class="pa-3 text-white font-weight-bold">{{ item.bio }}</p>
                        </v-overlay>
                    </v-card></v-hover>
                <p class="text-center text-h6 text-title-1">{{ item.title }}</p>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="auto">
                <v-card variant="flat" min-width="300" max-width=400 theme="light"
                    color="#263238">
                    <v-card-title class="text-h4 mt-5">
                        {{ topics }}
                    </v-card-title>
                    <v-card-text class="text-h5 text-body-1 mt-5">
                        <v-list lines="one" height="600" rounded dense>
                            <v-list-item v-for="(item, i) in topicList" :key="i">
                                {{ item.title }}
                                <template v-slot:prepend>
                                    <v-icon color="indigo" size="small" icon="mdi-decagram"></v-icon>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="auto">
                <v-card variant="flat" min-width="300" max-width=400 theme="light"
                    color="#263238">
                    <v-card-title class="text-h4 mt-5">
                        {{ agenda }}
                    </v-card-title>
                    <v-card-text class="text-h5 text-body-1 mt-5">
                        <v-list lines="two" height="600" rounded>
                            <v-list-item v-for="(item, i) in agendaItems" :key="i" :title="item.title"
                                :subtitle="item.subtitle"></v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
export default {
    name: "SpeakerSection",
    data: () => ({
        title : "Hear from Raleigh based industry leaders",
        description: "Join us for an exclusive event where aspiring IT leaders can learn from experienced professionals, gaining insights into value creation, navigating internal politics, and leveraging strategic partnerships to achieve sustainable success.",
        topics: "Topics",
        agenda: "Agenda",
        speakers: [
            { name : "Jim Palermo", title: "Red Hat, CIO (Former)", picture: "jim-palermo.png", bio: "Jim Palermo is the former CIO of Red Hat, driving IT transformation and innovation, with expertise in AI, cloud computing and cybersecurity. Recently awarded the 2023 CIO 100 Award, he has modernized Red Hat’s hybrid cloud infrastructure and spearheaded AI initiatives."},
            { name : "Chandra Venkatapathy", title: "Dell, Global CTO (Former)", picture: "chandra-venkatapathy.png", bio: "Chandra Venkatapathy is the Global CTO at Dell Technologies, focusing on retail technology solutions and partnerships. With 20+ years in industry, he has driven significant growth and led transformations in customer experience and supply chain management. His expertise includes AI, cloud computing, and consultative selling."},
            { name : "David Costar", title: "Wolfspeed, CIO (Former)", picture: "david-costar.png", bio: "David Costar is an enterprise transformation leader and CIO with expertise in scaling businesses and operational excellence. He led a $45M digital transformation at Wolfspeed, driving substantial revenue growth and cost reductions. With a strong background in managing complex digital projects, he fosters innovation and optimizes budgets, consistently improving efficiency and transparency in organizations."},
            { name : "John Herson", title: "Connxions, CTO", picture: "john-herson.png", bio: "John Herson is the CTO at ConnXions, with recent experience as Director of Engineering for Enterprise Applications at Rapid7. He has a strong background in enterprise architecture and technology alignment with business strategies, specializing in cloud technologies like AWS and Salesforce.com."}
        ],
        topicList: [
            { title : "Importance of IT-business alignment", subtitle: "" },
            { title : "Building a robust IT strategy with the C-suite", subtitle: "" },
            { title : "Common pitfalls to avoid in the strategic planning phase", subtitle: "" },
            { title : "Managing large-scale IT projects and ensuring execution aligns with vision", subtitle: "" },
            { title : "Tools and frameworks for successful project execution.", subtitle: "" },
            { title : "Lessons learned from past failures and successes", subtitle: "" },
            { title : "Missteps in technology adoption and integration", subtitle: "" },
            { title : "Misalignment between culture and transformation goals", subtitle: "" },
            { title : "Case studies of failed transformation efforts and lessons learned", subtitle: "" },
            { title : "Implementing agile methodologies for continuous improvement", subtitle: "" },
            { title : "Leveraging AI, cloud, and automation for future transformation success", subtitle: "" },
            { title : "Fostering a culture of innovation to ensure long-term agility", subtitle: "" },
        ],
        agendaItems: [
            { title : "5:30pm", subtitle: "Cocktail Reception and Buffet"},
            { title : "6:20pm", subtitle: "Welcome and Opening Remarks"},
            { title : "6:35pm - Jim Palermo", subtitle: "Setting the Foundation: Strategy First"},
            { title : "6:50pm - Chandra Venkatapathy", subtitle: "From Vision to Execution: Scaling for Success"},
            { title : "7:15pm - David Costar", subtitle: "Avoiding the Pitfalls: What Doesn’t Work"},
            { title : "7:30pm - John Herson", subtitle: "Future-Proofing Your IT: What Works in the Long Run"},
            { title : "7:45pm", subtitle: "Panel Discussion"},
            { title : "8:00pm", subtitle: "Closing Remarks and Key Takeaways"}
        ]
    }),
};
</script>

<style scoped>


</style>